import { Theme } from '@mui/material'
import { Row } from '@tanstack/react-table'

import { useMergeFormData } from 'components/Form'
import { Table, usePagination } from 'components/Table'
import { PaginationProps } from 'components/Table/Pagination'
import { useSort } from 'components/Table/Sort'
import { useErrorsOnPagesInfo } from 'features/globalSetup/hooks/useErrorsOnPagesInfo'
import { useFooterInfoProps } from 'features/globalSetup/hooks/useFooterInfoProps'
import { calculatePagination } from 'providers/graphql'
import { Schema } from '../../form/schema'
import { AdUnitBidderSlice, useGetAdUnitBidders } from '../../hooks/UseGetAdUnitBidders'
import { DEFAULT_SORT, SORTABLE_COLUMNS } from './constants'
import { useCollectInventoryConnectionDirtyRows } from './hooks/useCollectInventoryConnectionDirtyRows'
import { useErrors } from './hooks/useErrors'
import { AdUnitsForTable, AdUnitSortBy, AdUnitsTableProps } from './types'
import { useColumns } from './useColumns'
import { adUnitBiddersToSchema, getRequiredParamsNames } from './utils'

export const AdUnitsTable = ({ domainBidder, filters }: AdUnitsTableProps): JSX.Element => {
  const {
    domain: { id: domainId },
    workspaceBidder: {
      bidder: { s2sAvailable },
      status,
    },
  } = domainBidder

  const { page, rowsPerPage, setPage, setRowsPerPage } = usePagination({
    resetOnChange: [domainId, filters],
  })
  const { props: sortProps, sort } = useSort<AdUnitsForTable, AdUnitSortBy>(
    DEFAULT_SORT,
    SORTABLE_COLUMNS,
  )

  const { adUnitBidders, count, loading } = useGetAdUnitBidders({
    domainBidder,
    ...calculatePagination({ page, rowsPerPage }),
    filters,
    sort,
  })

  const { csParams, s2sParams } = adUnitBidders[0] || {}
  const csRequiredParamsNames = csParams ? getRequiredParamsNames(csParams) : []
  const s2sRequiredParamsNames = s2sParams ? getRequiredParamsNames(s2sParams) : []

  const columns = useColumns({
    cs: { csParams, csRequiredParamsNames },
    s2s: { s2sParams: s2sAvailable ? s2sParams : undefined, s2sRequiredParamsNames },
    workspaceStatus: status,
  })

  useMergeFormData<Schema>({
    data: adUnitBiddersToSchema(adUnitBidders),
    path: 'adUnitBidders',
    resetDependency: domainId,
    resetValue: {},
  })

  useErrors({ adUnitBidders })
  useCollectInventoryConnectionDirtyRows()

  const paginationProps: PaginationProps = {
    count,
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
  }

  const { pagesWithError } = useErrorsOnPagesInfo<Schema>({
    current: { page, rowsPerPage },
    ids: adUnitBidders.map((el: AdUnitBidderSlice): string => el.id),
    schemaPath: 'adUnitBidders',
    updateDependency: adUnitBidders,
  })
  const footerInfoProps = useFooterInfoProps({ pagesWithError })

  return (
    <Table<AdUnitsForTable>
      columns={columns}
      data={adUnitBidders}
      footerInfo={footerInfoProps}
      loading={loading}
      meta={{
        styleRow: (row: Row<AdUnitsForTable>, theme: Theme) =>
          row.original.adUnit.unfilledRecoveryAdUnit
            ? { backgroundColor: theme.palette.cream.main }
            : {},
        verticalBorders: true,
      }}
      pagination={paginationProps}
      sort={sortProps}
    />
  )
}
