import { createContext } from 'react'

import type { PaginationProps } from 'components/Table/Pagination'
import type { UseGetAuditLogs } from 'features/history/hooks/useGetAuditLogs'

export type ContextProps = {
  pagination: PaginationProps
} & Pick<UseGetAuditLogs, 'auditLogs' | 'loading'>

export const Context = createContext<ContextProps>({} as ContextProps)
