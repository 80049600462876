import Loading from 'components/Loading'
import { Iframe } from 'features/performance'
import { useGetPortalToken } from '../hooks/useGetPortalToken'
import { PORTAL_IFRAME_HEIGHT, PORTAL_URL } from './constants'

export const PortalPage = (): JSX.Element => {
  const { loading, token } = useGetPortalToken()

  if (loading || token === undefined) {
    return <Loading />
  }

  return (
    <Iframe
      height={PORTAL_IFRAME_HEIGHT}
      src={PORTAL_URL(token)}
    />
  )
}
