import { Box, Switch as MuiSwitch, SwitchProps as MuiSwitchProps } from '@mui/material'
import _ from 'lodash'

import Tooltip from 'components/Tooltip'
import { SwitchProps } from './types'

export const Switch = (props: SwitchProps): JSX.Element => {
  const { tooltip, tooltipMode } = props

  return tooltip && tooltipMode === 'wrap' ? (
    <Tooltip {...tooltip}>
      <Box>{<SwitchContainer {...props} />}</Box>
    </Tooltip>
  ) : (
    <SwitchContainer {...props} />
  )
}

const SwitchContainer = (props: SwitchProps): JSX.Element => {
  const muiSwitchProps: MuiSwitchProps = _.omit<SwitchProps>(
    props,
    'checked',
    'tooltip',
    'tooltipMode',
  )

  return (
    <MuiSwitch
      {...muiSwitchProps}
      checked={props.checked}
    />
  )
}
