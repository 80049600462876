import { ColumnDef } from '@tanstack/react-table'
import { sentenceCase } from 'change-case'
import { upperFirst } from 'lodash'
import { useTranslation } from 'react-i18next'

import { BoxWithLogo } from 'components/Box'
import { COLUMN_SIZE } from 'components/Table/constants'
import { generateSelector } from 'providers/product-fruits/utils'
import { parseBidderLogo } from '../utils'
import { ActionsCell } from './components/ActionsCell'
import { EnabledCell } from './components/EnabledCell'
import { BiddersForTable } from './types'

export const useColumns = (): ColumnDef<BiddersForTable>[] => {
  const { t } = useTranslation(['features/globalSetup', 'common'])

  return [
    {
      accessorKey: 'name',
      cell: ({
        row: {
          index,
          original: { accountType, name },
        },
      }) => (
        <div
          data-product-fruits={
            index === 0 ? generateSelector({ step: 6, subTour: 2, tour: 3 }) : undefined
          }
        >
          <BoxWithLogo logo={{ size: 'small', svg: parseBidderLogo({ accountType, name }) }}>
            {name}
          </BoxWithLogo>
        </div>
      ),
      header: t('prebid.bidders.list.header.name'),
    },
    {
      accessorKey: 'code',
      header: t('prebid.bidders.list.header.code.label'),
      meta: {
        tooltip: { content: t('prebid.bidders.list.header.code.tooltip') },
      },
    },
    {
      accessorKey: 'accountType',
      cell: ({
        row: {
          original: { accountType, supplyChain },
        },
      }) => upperFirst(accountType === 'thirdPartyProvider' ? supplyChain?.name : accountType),
      header: t('prebid.bidders.list.header.account.label'),
      meta: {
        tooltip: {
          content: [
            t('prebid.bidders.list.header.account.tooltip.line1'),
            t('prebid.bidders.list.header.account.tooltip.line2'),
            t('prebid.bidders.list.header.account.tooltip.line3'),
          ],
        },
      },
    },
    {
      accessorKey: 'bidCpmAdjustment',
      cell: ({
        row: {
          original: { bidCpmAdjustment: value },
        },
      }) => t('common:percentage', { value }),
      header: t('prebid.bidders.list.header.bidCPMAdjustment'),
    },
    {
      accessorKey: 'consentMode',
      cell: ({ getValue }) =>
        t(`prebid.bidders.list.consentMode.${getValue<BiddersForTable['consentMode']>()}`),
      header: t('prebid.bidders.list.header.consentMode.label'),
      meta: {
        tooltip: {
          content: [
            t('prebid.bidders.list.header.consentMode.tooltip.line1'),
            t('prebid.bidders.list.header.consentMode.tooltip.line2'),
            t('prebid.bidders.list.header.consentMode.tooltip.line3'),
            t('prebid.bidders.list.header.consentMode.tooltip.line4'),
          ],
        },
      },
    },
    {
      accessorKey: 'priority',
      cell: ({ getValue }) => sentenceCase(getValue<BiddersForTable['priority']>()),
      header: t('prebid.bidders.list.header.priority.label'),
      meta: {
        tooltip: { content: t('prebid.bidders.list.header.priority.tooltip') },
      },
    },
    {
      accessorKey: 'enabled',
      cell: props => <EnabledCell {...props} />,
      header: t('prebid.bidders.list.header.enabled'),
      meta: {
        headerProductFruitsSelector: generateSelector({ step: 2, subTour: 1, tour: 3 }),
        nonClickable: true,
      },
    },
    {
      accessorKey: 'actions',
      cell: props => <ActionsCell {...props} />,
      header: t('prebid.bidders.list.header.actions'),
      meta: { nonClickable: true },
      size: COLUMN_SIZE.SM,
    },
  ]
}
