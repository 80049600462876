import _ from 'lodash'
import { useEffect, useState } from 'react'
import { FieldValues, useFormContext } from 'react-hook-form'

import { ElementPerPage, UseErrorsOnPagesInfo, UseErrorsOnPagesInfoProps } from './types'

export const useErrorsOnPagesInfo = <Schema extends FieldValues>({
  current: { page, rowsPerPage },
  ids,
  schemaPath,
  updateDependency,
}: UseErrorsOnPagesInfoProps<Schema>): UseErrorsOnPagesInfo => {
  const [tempPerPage, setTempPerPage] = useState<ElementPerPage[]>([])
  const [allPerPage, setAllPerPage] = useState<ElementPerPage[]>([])
  const [pagesWithError, setPagesWithError] = useState<number[]>([])

  const {
    formState,
    formState: { errors },
  } = useFormContext<Schema>()

  useEffect(() => {
    const tempPerPage: ElementPerPage[] = ids.map(
      (id: string): ElementPerPage => ({
        id,
        pageNumber: page,
      }),
    )

    setTempPerPage(tempPerPage)
  }, [updateDependency, page]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const mergedUniqueAllPerPage = _.uniqBy<ElementPerPage>([...allPerPage, ...tempPerPage], 'id')

    setAllPerPage(mergedUniqueAllPerPage)
  }, [tempPerPage]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_.isEmpty(errors)) {
      setPagesWithError([])
      return
    }

    const pagesWithError = _.chain<ElementPerPage>(allPerPage)
      .filter((el: ElementPerPage): boolean => _.keys(errors[schemaPath]).includes(el.id))
      .map((el: ElementPerPage): number => el.pageNumber)
      .uniq()
      .value()

    setPagesWithError(pagesWithError)
  }, [formState, allPerPage]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setAllPerPage([])
  }, [rowsPerPage])

  return { pagesWithError }
}
