import { SvgIcon, Tooltip as TooltipRoot } from '@mui/material'

import { TextLink } from 'components/Link'
import { Content } from './Content'
import { chooseIcon, chooseIconColor } from './icon'
import { TooltipProps } from './types'

export const Tooltip = (props: TooltipProps): JSX.Element => {
  const { children, content, link, placement }: TooltipProps = props

  const IconContainer: JSX.Element = (
    <SvgIcon
      component={chooseIcon(props)}
      fontSize='small'
      onClick={props.onClick}
      sx={{
        alignSelf: 'center',
        color: theme => chooseIconColor({ props, theme }),
        cursor: props.onClick ? 'pointer' : 'default',
        ml: 1,
      }}
    />
  )

  return content ? (
    <TooltipRoot
      placement={placement ?? 'right-start'}
      title={
        <>
          {<Content content={content} />}
          {link && <TextLink link={link} />}
        </>
      }
    >
      {children || IconContainer}
    </TooltipRoot>
  ) : (
    <>{children}</>
  )
}
