import { useTranslation } from 'react-i18next'

import { PageHeader } from 'components/Header'
import Paper from 'components/Paper'
import { usePagination } from 'components/Table'
import { useGetAuditLogs } from 'features/history/hooks/useGetAuditLogs'
import { calculatePagination } from 'providers/graphql'
import { Context } from './Context'
import { FormContainer } from './form/FormContainer'

import type { PaginationProps } from 'components/Table/Pagination'

export const Page = (): JSX.Element => {
  const { t } = useTranslation('features/history')
  const { page, rowsPerPage, setPage, setRowsPerPage } = usePagination()
  const { auditLogs, count, loading } = useGetAuditLogs({
    ...calculatePagination({ page, rowsPerPage }),
  })

  const paginationProps: PaginationProps = {
    count,
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
  }

  return (
    <>
      <PageHeader
        description={{ text: t('description') }}
        title={t('title')}
      />

      <Paper>
        <Context.Provider
          value={{
            auditLogs,
            loading,
            pagination: paginationProps,
          }}
        >
          <FormContainer />
        </Context.Provider>
      </Paper>
    </>
  )
}
