import { useContext, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { BoxForm, CodeField } from 'components/Form'
import Pagination from 'components/Table/Pagination'
import { EmptyTableFooter } from 'components/Table/Table/components/EmptyTableFooter'
import { Context } from '../Context'
import { AUDIT_LOGS_INPUT_HEIGHT } from './constants'

import type { Schema } from './schema'

export const Form = (): JSX.Element => {
  const { t } = useTranslation('features/history')
  const { auditLogs, pagination } = useContext(Context)
  const { control, setValue } = useFormContext<Schema>()

  useEffect(() => {
    if (auditLogs !== undefined) {
      setValue('auditLogs', auditLogs)
    }
  }, [auditLogs]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BoxForm>
      <CodeField
        control={control}
        disabled
        height={AUDIT_LOGS_INPUT_HEIGHT}
        language='json'
        name='auditLogs'
        title={t('title')}
      />

      <EmptyTableFooter>
        <Pagination {...pagination} />
      </EmptyTableFooter>
    </BoxForm>
  )
}
