import { Logo } from 'components/Box/BoxWithLogo/types'
import { bidderLogos } from 'constants/bidderLogos'
import { Bidder } from 'features/globalSetup/types/bidder'
import { Account } from 'features/globalSetup/types/workspaceBidder'

export const parseBidderLogo = ({
  accountType,
  name,
}: {
  accountType: Account
  name: Bidder['name']
}): Logo['svg'] => {
  let tempName: string = name

  if (accountType === 'yieldbird') {
    // they have in API extra `Yb` characters at the end
    tempName = tempName.slice(0, -2)
  }

  return bidderLogos[tempName]
}
