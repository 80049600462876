import { SvgIconComponent } from '@mui/icons-material'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import { PaletteColor, Theme } from '@mui/material'

import { TooltipProps } from './types'

export const chooseIcon = (props: TooltipProps): SvgIconComponent => {
  const { variant } = props

  switch (variant) {
    case 'custom': {
      return props.customIcon.icon
    }
    case 'error':
      return ErrorOutlineOutlinedIcon
    case 'help':
      return HelpOutlineOutlinedIcon
    default:
      return HelpOutlineOutlinedIcon
  }
}

export const chooseIconColor = ({
  props,
  theme,
}: {
  props: TooltipProps
  theme: Theme
}): PaletteColor['main'] => {
  const { variant } = props

  const helpColor: PaletteColor['main'] = theme.palette.grey[400]

  switch (variant) {
    case 'custom': {
      return props.customIcon.color || theme.palette.black.main
    }
    case 'error':
      return theme.palette.error.main
    case 'help':
      return helpColor
    default:
      return helpColor
  }
}
