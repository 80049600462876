import { mapDTO } from '@twistezo/ts-dto-mapper'

import type { AuditLog } from 'features/history/types/auditLog'
import type { AuditLogDTO } from 'features/history/types/auditLogDTO'

type From = AuditLogDTO
type To = AuditLog

export const fromDTO = (from: From): To =>
  mapDTO<From, To>({ from }).transform((auditLog: From): To => {
    const { logEntry } = auditLog
    const { changes, log } = parseLog(logEntry)

    return { changes, log }
  })

export const parseLog = (logEntry: AuditLogDTO['logEntry']): AuditLog => {
  // Matches `with data: {.*} ` fragment and captures Ruby object `{.*}`
  const match: null | RegExpMatchArray = logEntry.match(/with data: ({.*}) /)

  if (match === null) {
    return { changes: undefined, log: logEntry }
  }
  const [dataMatch, capturedObject] = match

  const parsedObject: string = capturedObject
    .replace(/"?(\w+)"?\s*=>/g, '"$1":') // convert '=>' to ':'
    .replace(/\bnil\b/g, 'null') // convert 'nil' to 'null'

  const cleanedLog: string = logEntry.replace(dataMatch, '').trim()

  return {
    changes: JSON.parse(parsedObject),
    log: cleanedLog,
  }
}
