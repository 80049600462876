import { mapDTO } from '@twistezo/ts-dto-mapper'

import { PortalToken } from '../types'
import { PortalTokenDTO } from './types'

type From = PortalTokenDTO
type To = PortalToken

export const fromDTO = (from: From): To =>
  mapDTO<From, To>({ from }).transform((token: From): To => token)
