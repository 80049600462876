import { useQuery } from '@apollo/client'
import { useState } from 'react'

import { fromDTO } from './api/mapper'
import { GET_PORTAL_TOKEN } from './api/query'
import { PortalToken, UseGetPortalToken } from './types'

export const useGetPortalToken = (): UseGetPortalToken => {
  const [token, setToken] = useState<PortalToken>()

  const { loading } = useQuery(GET_PORTAL_TOKEN, {
    onCompleted: ({ portalToken }) => {
      setToken(fromDTO(portalToken))
    },
  })

  return {
    loading,
    token,
  }
}
