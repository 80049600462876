import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import Loading from 'components/Loading'
import Paper from 'components/Paper'
import { FINANCE_IFRAME_HEIGHT } from 'features/finance/constants'
import { FormContainer } from 'features/finance/forms/AdminOptionForm'
import { Iframe } from 'features/performance'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { useAbility } from 'providers/casl'
import { useGetFinanceReport } from './useGetFinanceReport'

export const FinancePage = (): JSX.Element => {
  const { workspaceId } = useWorkspaceParam()
  const ability = useAbility()
  const { t } = useTranslation('common')
  const { financeReport } = useGetFinanceReport({ workspaceId })

  if (!financeReport) {
    return <Loading />
  }

  const hasAccess: boolean =
    ability.can('read', 'Finance-adminOption') && ability.can('update', 'Finance-adminOption')

  const { financeReportUrl } = financeReport

  return (
    <>
      {hasAccess && <FormContainer data={financeReport} />}

      {financeReportUrl === undefined ? (
        <Paper>
          <Typography>{t('report.notReady')}</Typography>
        </Paper>
      ) : (
        <Iframe
          height={FINANCE_IFRAME_HEIGHT}
          src={financeReportUrl}
        />
      )}
    </>
  )
}
