import { useContext } from 'react'

import { Form as FormProvider } from 'components/Form'
import { Context } from '../Context'
import { Form } from './Form'
import { defaults, schema } from './schema'

import type { Schema } from './schema'

export const FormContainer = (): JSX.Element => {
  const { loading } = useContext(Context)

  return (
    <FormProvider<Schema>
      id='history-form'
      loading={loading}
      schema={schema}
      schemaDefaults={defaults}
    >
      {() => <Form />}
    </FormProvider>
  )
}
