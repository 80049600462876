import { gql, TypedDocumentNode } from '@apollo/client'

import { AUDIT_LOG_FRAGMENT } from './fragment'
import { Data, Variables } from './types'

export const GET_AUDIT_LOGS: TypedDocumentNode<Data, Variables> = gql`
  ${AUDIT_LOG_FRAGMENT}

  query getAuditLogs($first: Int, $last: Int) {
    auditLogs(first: $first, last: $last) {
      nodes {
        ...AuditLogFragment
      }

      totalCount
    }
  }
`
