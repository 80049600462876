import { useFormContext } from 'react-hook-form'

import { TextField } from 'components/Form'
import { TextFieldProps } from 'components/Form/TextField'
import { Schema } from 'features/globalSetup/pages/Prebid/Bidders/EditBidderPage/pages/BidParamsAndConnection/form/schema'
import { AdUnitParameter } from 'features/globalSetup/types/adUnitBidder'
import { useAbility } from 'providers/casl'
import { useExistingWorkspaceBidder } from '../../../../hooks/useExistingWorkspaceBidder'
import { AdUnitBidderParamFieldPath, Props } from './types'

export const AdUnitParamInputCell = ({
  cellContext: {
    cell: { getValue },
    row: {
      original: { id: adUnitBidderId },
    },
  },
  kind,
}: Props): JSX.Element | undefined => {
  const ability = useAbility()
  const { clearErrors, control, getValues, register } = useFormContext<Schema>()
  const {
    workspaceBidder: { accountType },
  } = useExistingWorkspaceBidder()

  const { name, requiredOff, value } = getValue() as AdUnitParameter

  const fieldPath: AdUnitBidderParamFieldPath = `adUnitBidders.${adUnitBidderId}.${kind}Params.${name}`

  if (getValues(fieldPath) === undefined) {
    register(fieldPath, { value })
  }

  const isDisabledByAuth = (): boolean => {
    switch (accountType) {
      case 'own':
        return ability.cannot('update', 'GlobalSetupField-prebid-bidders-bid-params-bid-param-own')
      case 'thirdPartyProvider':
        return ability.cannot(
          'update',
          'GlobalSetupField-prebid-bidders-bid-params-bid-param-thirdPartyProvider',
        )
      case 'yieldbird':
        return ability.cannot(
          'update',
          'GlobalSetupField-prebid-bidders-bid-params-bid-param-yielbdird',
        )
    }
  }

  const inputProps: TextFieldProps<Schema> = {
    control,
    disabled: isDisabledByAuth(),
    key: `${kind}-${name}`,
    name: fieldPath,
    useTooltipForErrors: true,
  }

  return (
    <TextField
      {...inputProps}
      onClearError={requiredOff ? () => void clearErrors(fieldPath) : undefined}
      width='sm'
    />
  )
}
