import { TaskAlt } from '@mui/icons-material'
import { Stack, SxProps, TextField as TextFieldRoot, Theme, useTheme } from '@mui/material'
import { Controller, FieldPath, FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FlexBox } from 'components/Box'
import Tooltip from 'components/Tooltip'
import { inputWidth } from 'providers/material-ui/theme/constants'
import { TextFieldProps } from './types'

const prepareValue = <T extends FieldValues>(
  fieldValue: string,
  componentValue: TextFieldProps<T>['value'],
): string => {
  if (fieldValue !== undefined) {
    return fieldValue
  } else if (typeof componentValue === 'string' && componentValue !== undefined) {
    return componentValue
  } else return ''
}

export const TextField = <T extends FieldValues>({
  control,
  name,
  onClearError,
  tooltip: tooltipProps,
  useTooltipForErrors,
  width,
  ...textFieldProps
}: TextFieldProps<T>): JSX.Element => {
  const { t } = useTranslation('common')
  const {
    palette: { success },
  } = useTheme()

  return (
    <Controller
      control={control}
      name={name as FieldPath<T>}
      render={({ field, field: { value }, fieldState: { error } }) => {
        const hasError: boolean = Boolean(error)
        const errorMessage: string = error?.message || ''

        const calculatedWidth: number | string = inputWidth[width ?? 'md']

        const containerSxProps: SxProps<Theme> = {
          height: 'fit-content',
          minWidth: calculatedWidth,
        }

        const inputSxProps: SxProps<Theme> = {
          ...textFieldProps.sx,
          minWidth: calculatedWidth,
          width: calculatedWidth,
        }

        return (
          <Stack
            direction='row'
            sx={containerSxProps}
          >
            <TextFieldRoot
              {...field}
              {...textFieldProps}
              error={hasError}
              helperText={!useTooltipForErrors && errorMessage}
              id={name}
              InputLabelProps={{ shrink: true }}
              sx={inputSxProps}
              value={prepareValue(value, textFieldProps.value)}
            />

            {tooltipProps && <Tooltip {...tooltipProps} />}

            {hasError && useTooltipForErrors && (
              <FlexBox
                axis='y'
                sx={{ gap: 1, justifyContent: 'center' }}
              >
                <Tooltip
                  content={errorMessage}
                  variant='error'
                />

                {onClearError && (
                  <Tooltip
                    content={t('form.error.confirm')}
                    customIcon={{ color: success.main, icon: TaskAlt }}
                    onClick={onClearError}
                    variant='custom'
                  />
                )}
              </FlexBox>
            )}
          </Stack>
        )
      }}
    />
  )
}
