import { RouteObject } from 'react-router-dom'

import { Page as HistoryPage } from 'features/history/pages/HistoryPage'
import AuthorizedRoute from 'providers/router/components/AuthorizedRoute'
import { ROUTES } from './routePaths'

export const historyRoutes: RouteObject[] = [
  {
    element: (
      <AuthorizedRoute
        access='HistoryPage'
        page={<HistoryPage />}
      />
    ),
    path: ROUTES.HISTORY,
  },
]
