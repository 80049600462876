import { ColumnDef } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { LastModifiedCell } from 'components/Table'
import { COLUMN_SIZE } from 'components/Table/constants'
import Tooltip from 'components/Tooltip'
import { WorkspacePrebidModuleSlice } from 'features/globalSetup/pages/Prebid/Modules/hooks/useGetWorkspacePrebidModules'
import { PrebidModule } from 'features/globalSetup/types/prebidModule'
import { generateSelector } from 'providers/product-fruits/utils'
import { GENERAL_PREBID_MODULES } from '../../constants'
import { ActionsCell } from './components/ActionsCell'
import { EnabledCell } from './components/EnabledCell'

export const useColumns = (): ColumnDef<WorkspacePrebidModuleSlice>[] => {
  const { t } = useTranslation(['features/globalSetup', 'common'])

  const generateName = (name: PrebidModule['name']): JSX.Element | string => {
    const { FPD, PAAPI } = GENERAL_PREBID_MODULES
    const isFPD: boolean = name === FPD.name

    if (!isFPD && name !== PAAPI.name) {
      return name
    }

    return (
      <>
        {name}
        <Tooltip
          content={isFPD ? FPD.tooltip.content : PAAPI.tooltip.content}
          link={{ title: t('common:learnMore'), url: isFPD ? FPD.tooltip.url : PAAPI.tooltip.url }}
        />
      </>
    )
  }

  return [
    {
      accessorKey: 'prebidModule.name',
      cell: ({
        row: {
          original: {
            prebidModule: { name },
          },
        },
      }) => generateName(name),
      header: t('prebid.modules.general.list.header.name'),
    },
    {
      accessorKey: 'enabled',
      cell: props => <EnabledCell {...props} />,
      header: t('prebid.modules.general.list.header.enabled'),
      meta: {
        headerProductFruitsSelector: generateSelector({ step: 4, tour: 4 }),
        nonClickable: true,
      },
    },
    {
      accessorKey: 'updatedAt',
      cell: props => <LastModifiedCell {...props} />,
      header: t('prebid.modules.general.list.header.lastModified'),
    },
    {
      cell: ({ row }) => <ActionsCell {...row.original} />,
      header: t('prebid.modules.general.list.header.actions'),
      id: 'actions',
      meta: { centered: true, nonClickable: true },
      size: COLUMN_SIZE.SM,
    },
  ]
}
