import { mapDTO } from '@twistezo/ts-dto-mapper'

import { SelectOption } from 'components/Select'
import { parseBidderLogo } from 'features/globalSetup/pages/Prebid/Bidders/utils'
import { Account } from 'features/globalSetup/types/workspaceBidder'
import { BidderSlice } from '../../../../types'
import { BidderSliceDTO } from './types'

type From = BidderSliceDTO
type To = SelectOption<BidderSlice>

export const fromDTO = (from: From, accountType: Account): To =>
  mapDTO<From, To>({ from }).transform(
    ({ code, id, name, ybSid }: From): To => ({
      label: name,
      logo: parseBidderLogo({ accountType, name }),
      rawData: {
        code,
        id,
        name,
        ybSid: ybSid || undefined,
      },
      value: id,
    }),
  )
