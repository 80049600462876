import {
  Box,
  FormControlLabel,
  FormLabel,
  Switch,
  ToggleButton,
  Tooltip as TooltipRoot,
} from '@mui/material'
import { Controller, FieldPath, FieldValues } from 'react-hook-form'

import { FlexBox } from 'components/Box'
import Tooltip from 'components/Tooltip'
import { SwitchWithAutoButtonProps } from './types'
import { Value, valueMap } from './value'

export const SwitchWithAutoButton = <T extends FieldValues>({
  control,
  disabled,
  hint,
  label,
  name,
  tooltip,
}: SwitchWithAutoButtonProps<T>): JSX.Element => {
  return (
    <Controller
      control={control}
      name={name as FieldPath<T>}
      render={({ field: { onChange, value } }): JSX.Element => (
        <FlexBox axis='x'>
          <FormControlLabel
            control={
              <Switch
                checked={valueMap[value]}
                disabled={disabled || value === 'auto'}
                name={name}
                onChange={(_, checked: boolean): void =>
                  checked === true ? onChange('on') : onChange('off')
                }
              />
            }
            label={<FormLabel>{label}</FormLabel>}
            labelPlacement='start'
            sx={{ ml: 0 }}
          />

          <TooltipRoot
            arrow
            placement='top-start'
            title={hint}
          >
            <Box>
              <ToggleButton
                color='info'
                disabled={disabled}
                name={`${name}-auto`}
                onChange={(_, newValue: Value): void =>
                  value === 'auto' ? onChange('on') : onChange(newValue)
                }
                selected={value === 'auto'}
                sx={{ ml: 2 }}
                value={'auto'}
              >
                {'auto'.toUpperCase()}
              </ToggleButton>
            </Box>
          </TooltipRoot>

          {tooltip && <Tooltip {...tooltip} />}
        </FlexBox>
      )}
    />
  )
}
