import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import Loading from 'components/Loading'
import Paper from 'components/Paper'
import Iframe from 'features/performance/components/Iframe'
import { PERFORMANCE_IFRAME_HEIGHT } from 'features/performance/constants'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { useAbility } from 'providers/casl'
import { FormContainer } from './forms/AdminOptionForm'
import { useGetPerformanceReport } from './hooks/useGetPerformanceReport'

export const PerformanceGeneralPage = (): JSX.Element => {
  const { workspaceId } = useWorkspaceParam()
  const ability = useAbility()

  const { t } = useTranslation('common')

  const { performanceReport } = useGetPerformanceReport({ workspaceId })

  if (!performanceReport) {
    return <Loading />
  }

  const { performanceReportUrl } = performanceReport

  const hasAccess: boolean =
    ability.can('read', 'Performance-adminOption') &&
    ability.can('update', 'Performance-adminOption')

  return (
    <>
      {hasAccess && <FormContainer data={performanceReport} />}

      {performanceReportUrl ? (
        <Iframe
          height={PERFORMANCE_IFRAME_HEIGHT}
          src={performanceReportUrl}
        />
      ) : (
        <Paper>
          <Typography>{t('report.notReady')}</Typography>
        </Paper>
      )}
    </>
  )
}
