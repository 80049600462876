import { useQuery } from '@apollo/client'
import { useState } from 'react'

import { JSON_SPACE } from 'constants/json'
import { AuditLog } from 'features/history/types/auditLog'
import { AuditLogDTO } from 'features/history/types/auditLogDTO'
import { fromDTO } from './api/mapper'
import { GET_AUDIT_LOGS } from './api/query'

import type { UseGetAuditLogs, UseGetAuditLogsProps } from './types'

export const useGetAuditLogs = ({ first, last }: UseGetAuditLogsProps): UseGetAuditLogs => {
  const [data, setData] = useState<string>()
  const [count, setCount] = useState<number>(0)

  const { loading } = useQuery(GET_AUDIT_LOGS, {
    onCompleted: ({ auditLogs: { nodes, totalCount } }): void => {
      if (nodes && nodes.length > 0) {
        const auditLogs: AuditLog[] = nodes.map((log: AuditLogDTO): AuditLog => fromDTO(log))
        const stringifiedLogs = JSON.stringify(auditLogs, null, JSON_SPACE)

        setData(stringifiedLogs)
      }

      setCount(totalCount)
    },

    variables: {
      first,
      last,
    },
  })

  return {
    auditLogs: data,
    count,
    loading,
  }
}
