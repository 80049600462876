import { z } from 'zod'

import { validateJSONSchema } from 'utils/json'

export const schema = z.object({
  auditLogs: z.string().superRefine(validateJSONSchema),
})

export type Schema = z.infer<typeof schema>

export const defaults: Schema = {
  auditLogs: '',
}
